.hero {
  padding: 1rem;
  height: 80vh;
  background-image: url(../../assets/background.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .hero {
    height: 90vh;
    padding: 3rem;
  }
}
.hero__msg {
  margin-top: 5rem;
}
@media (min-width: 768px) {
  .hero__msg {
    display: flex;
    align-items: center;
    padding-top: rem;
    margin-top: 2rem;
  }
}
.hero__msg h4 {
  font-family: "Computer says no";
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 50px;
  /* identical to box height, or 62% */
  /* white */
  color: #FFFFFF;
}
.hero__msg h4 span {
  background: #FFDE59;
  color: #FFFFFF;
  padding: 0.2rem;
  margin-top: 1.5rem;
}
@media (min-width: 768px) {
  .hero__msg h4 {
    width: 60%;
    font-weight: 400;
    font-size: 14rem;
    line-height: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hero__msg h4 {
    font-size: 5rem;
    line-height: 100px;
  }
}
.hero__msg p {
  margin-top: 1rem;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #FFFFFF;
  width: 80%;
}
@media (min-width: 768px) {
  .hero__msg p {
    font-weight: 400;
    font-size: 2rem;
    line-height: 52px;
    width: 30%;
    margin-left: 1rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hero__msg p {
    font-size: 26px;
    width: 50%;
  }
}
.hero__btn {
  border: 0px;
  position: absolute;
  right: 20%;
  top: 60%;
  background-color: inherit;
}
@media (min-width: 768px) {
  .hero__btn {
    top: 70%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hero__btn {
    top: 50%;
  }
}

.max {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
}/*# sourceMappingURL=hero.css.map */