.soon-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: 10rem;
}

.soon-header img {
  width: 200px;
  height: auto;
  margin: auto;
}

.soon-semi h1 {
  font-size: 44px;
  font-weight: 100;
}