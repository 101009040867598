.contact {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  background: white;
  border: 2px solid black;
  padding: 4em;
  border-radius: 8px;
  margin-top: -4em;
}
.poa__contact-container {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6em;
  font-family: var(--font-family);
}

.poa__contact-intro {
  text-align: center;
  margin-bottom: 32px;
}

.poa__contact-intro h1 {
  font-weight: 100;
  font-size: 104px;
  margin-bottom: 10px;
}

.poa__contact-intro p {
  font-size: 20px;
  font-weight: 300;
}

.poa__contact-intro span {
  background: var(--color-accent);
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formie {
  display: flex;
}

.poa__form {
  display: flex;
  margin-bottom: 24px;
}

.form-label {
  font-weight: 400;
}

.form-label span {
  display: block;
  text-align: left;
  margin-bottom: 5px;
}

.form-label input {
  width: 380px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid black;
  margin-right: 24px;
}

.form-label-mint {
  text-align: center;
}

.form-label select {
  width: 380px;
  padding: 12px;
  margin-right: 24px;
  border-radius: 8px;
  border: 1px solid black;
}

.form-label-mint span {
  display: block;
  text-align: left;
  margin-bottom: 5px;
}

.form-label-mint textarea {
  width: 784px;
  height: 153px;
  padding: 12px;
  isolation: isolate;
  font-family: var(--font-family);
  border-radius: 8px;
  border: 1px solid black;
  margin-bottom: 64px;
}

.contact-bg {
  background-color: #000000;
  margin-top: 12rem;
  padding: .1rem;
  position: relative;
}

.contact-button {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 8px;
  border-radius: 64px;
  background-color: var(--color-accent);
  border: 0;
  cursor: pointer;
}


@media screen and (max-width: 350px) {
  .contact {
    max-width: 100%;
  }

  .poa__contact-intro h1 {
    font-size: 60px;
  }
}

@media screen and (max-width: 650px) {

  .contact {
    max-width: 100%;
    padding: 16px !important;
    margin: 1rem;
    margin-top: -8rem;
  }

  .form-label select {
    margin-bottom: 16px;
  }

  .form-label input {
    width: 100% !important;
  }

  .poa__form {
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }

  .out {
    display: block !important;
  }

  .form {
    width: auto !important;
  }

  .formie {
    display: block !important;
  }

  .poa__form select {
    width: 100% !important;
    margin-right: 0;
  }

  .form-label-mint {
    width: 100%;
  }

  .form-label-mint textarea {
    width: 100%;
    margin-bottom: 48px;
  }

  .contact-button {
    width: 100%;
    margin-bottom: 24px;
  }

}


@media screen and (max-width: 850px) {
  .contact {
    max-width: 90%;
  }

  .form {
    width: fit-content;
  }

  .formie {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;
  }

  .poa__form {
    max-width: 100%;
  }

  .form-label {
    width: 44%;
  }

  .form-label input {
    width: 300px;
  }

  .form-label select {
    width: 300px;
  }

  .form-label-mint {
    width: 100%;
  }

  .form-label-mint textarea {
    width: 100%;
  }

  .out {
    display: flex;
    justify-content: space-between;
  }

}


@media screen and (max-width: 1280px) {
  .contact {
    max-width: 90%;
    padding: 2em;
  }

  .form-label-mint textarea {
    margin-bottom: 40px;
  }

  .poa__contact-intro h1 {
    font-size: 80px;
  }
}
