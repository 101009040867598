.poa__about-container {
  width: 100%;
  background: var(--color-black);
  padding: 8rem;
}

.poa__about-sub_container {
  background: var(--color-white);
  max-width: 1300px;
  padding: 4rem 4rem 3.5rem;
  border-radius: 12px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.poa__about-heading {
  width: 700px;
  margin-top: 40px;
}

.poa__about-heading h1 {
  font-size: 52px;
  font-weight: 400;
  padding-bottom: 20px;
}

.poa__about-heading h3 {
  font-size: 72px;
  font-weight: 400;
  margin-bottom: 20px;
  width: 694px;
}

.poa__about-heading h3 span {
  background-color: var(--color-accent);
  padding-right: 6px;
}

.poa__about-heading p {
  font-size: 20px;
  font-weight: 400;
  width: 567px;
  font-family: var(--font-family);
}

.poa__about-stats_container {
  width: 414px;
  background-color: var(--color-accent);
  border: 2px solid black;
  border-radius: 12px;
  padding: 1rem;
  display: block;
}

.poa__stats {
  display: flex;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
  font-family: var(--font-family);
  border: 2px solid black;
  border-radius: 10px;
  background-color: #FFFDF2;
  display: flex;
  justify-content: space-between;
}

.poa__stats h2 {
  font-size: 14px;
  font-weight: 400;
}

.poa__stats h3 {
  font-size: 18px;
  padding-top: .5em;
}

.poa__stats img {
  padding-left: 20px;
}

.poa__stats-img {
  text-align: center;
  padding-right: 20px;
}

.uno {
  margin-bottom: 12px;
}

.two {
  margin-bottom: 12px;
}



@media screen and (max-width: 350px) {
  .poa__about-container {
    padding: 2rem 1rem;
  }

  .poa__about-sub_container {
    display: block;
    text-align: center;
    max-width: 100%;
    padding: 0 0 20px 0px;
  }

  .poa__stats {
    text-align: left;
  }

  .poa__about-heading {
    width: 311px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .poa__about-heading h1 {
    font-size: 48px;
  }

  .poa__about-heading h3 {
    font-size: 36px;
    width: 311px;
  }

  .poa__about-heading p {
    font-size: 16px;
    width: 311px;
  }

  .poa__about-stats_container {
    display: block;
    width: 311px;
    margin-left: auto;
    margin-right: auto;
  }



}

@media screen and (max-width: 650px) { 
  .poa__about-container {
    padding: 2rem 1rem !important;
  }

  .poa__about-sub_container {
    display: block;
    text-align: center;
    max-width: 100%;
    padding: 16px 16px !important;
  }

  .poa__about-heading {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
  }

  .poa__about-heading h1 {
    font-size: 40px;
    text-align: center !important;
  }

  .poa__about-heading h3 {
    font-size: 56px;
    text-align: center !important;
  }

  .poa__about-heading p {
    font-size: 16px;
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    text-align: center !important;
  }

  .poa__stats {
    text-align: left;
  }

  .poa__about-stats_container {
    width: 100% !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}


@media screen and (max-width: 850px) {
  .poa__about-container {
    padding: 4rem 2rem;
  }

  .poa__about-sub_container {
    display: block;
    width: 100%;
    padding: 2rem;
  }

  .poa__about-heading {
    text-align: center;
    margin: auto;
    width: auto;
  }

  .poa__about-heading h1 {
    font-size: 80px;
  }

  .poa__about-heading h3 {
    width: fit-content;
  }  
  
  .poa__about-heading p {
    width: fit-content;
  }

  .poa__about-stats_container {
    margin-right: auto;
    margin-left: auto;
    margin-top: 2em;
  }

  hr {
    display: none;
  }

}


@media screen and (max-width: 1280px) {
  .poa__about-container {
    padding: 4rem 2rem;
  }

  .poa__about-sub_container {
    max-width: 100%;
    padding: 3rem;
  }

  .poa__about-heading {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .poa__about-heading h1 {
    text-align: left;
    font-size: 56px;
  }

  .poa__about-heading h3 {
    width: 100%;
    text-align: left;
  }

  .poa__about-heading p {
    text-align: left;
    width: 82%;
  }

  .poa__about-stats_container {
    width: 400px;
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
  }

  .poa__stats {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .poa__stats h2 {
    font-size: 14px;
  }

  .poa__stats h3 {
    margin-top: .5em;
    font-size: 16px;
  }

  .poa__stats img {
    width: 75px;
  }
}