.poa__why-container {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  text-align: center;
}

.poa__why-container-head {
  padding-top: 80px;
  font-weight: 400;
}

.poa__why-container-head h1 {
  font-weight: 100;
  font-size: 104px;
}

.poa__why-container-first {
  padding-top: 64px;
  padding-bottom: 20px;
  display: inline-flex;
}


.poa__first {
  display: flex;
  text-align: start;
  width: 500px;
  padding-right: 10px;
}

.poa__firstWhy {
  font-family: var(--font-family);
  padding-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.poa__firstWhy h3 {
  padding-bottom: 12px;
  font-size: 24px;
  font-weight: 600;
}

.poa__firstWhy p {
  font-size: 20px;
  font-weight: 300;
  width: 310px;
}

.poa__first img {
  width: 124px;
  height: 124px;
  margin-top: auto;
  margin-bottom: auto;
}

.second {
  padding-left: 40px;
}

.vertical {
  border-left: 0.5px solid black;
  height: 160px;
}

.line-set {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
}

.liner {
  height: 1px;
  width: 48%;
  border-top: 0.5px solid black;
}

.poa__why-container-second {
  padding-top: 20px;
  display: inline-flex;
}

/* Media Queries */

@media screen and (max-width: 350px) {

  .poa__why-container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
  }

  .line-set {
    display: none;
  }

  .vertical {
    display: none;
  }

  .line-set {
    display: none;
  }

  .poa__why-container-head h1 {
    font-size: 48px;
    font-weight: 100;
  }

  .poa__why-container-first {
    display: block;
    padding-bottom: 0;
  }

  .poa__why-container-second {
    display: block;
    padding: 0;
  }

  .poa__first {
    width: 100% !important;    
    padding-right: 0;
    padding-bottom: 24px;
    display: block;
    text-align: center;
  }

  .poa__first img {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .second {
    padding-left: 0;
    padding-bottom: 24px;
  }
}


@media screen and (max-width: 650px) { 
  .line-set {
    display: none;
  }

  .vertical {
    display: none;
  }

  .line-set {
    display: none;
  }

  .poa__why-container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
  }

  .poa__why-container-head h1 {
    font-size: 56px !important;
    text-align: center;
    font-weight: 100;
    width: 100% !important;
  }

  .poa__why-container-first {
    display: block;
    padding-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .poa__why-container-second {
    display: block;
    width: 100% !important;
    padding: 0;
  }

  .poa__first {
    margin: auto;
    padding-right: 0;
    padding-bottom: 24px;
    display: block;
    width: 100% !important;
  }

  .poa__first img {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .second {
    width: 100% !important;
    padding-left: 0;
    padding-bottom: 24px;
  }

  .poa__firstWhy {
    padding-left: 0;
  }

  .poa__firstWhy h3 {
    font-size: 20px;
    margin-top: 1rem;
  }

  .poa__firstWhy p {
    margin: auto;
    font-size: 16px;
  }
}

@media screen and (max-width: 856px) {
  .vertical {
    display: none;
  }

  .line-set {
    display: none;
  }

  .poa__why-container-first {
    text-align: center;
    padding: 0;
    width: fit-content;
    margin-top: 3em;
  }

  .poa__why-container-second {
    padding-top: 2em !important;
  }

  .poa__why-container-head h1 {
    font-size: 56px !important;
  }

  .poa__why {
    width: 700px;
  }

  .poa__first {
    display: block;
    text-align: center;
    width: 350px;
  }

  .poa__first img {
    width: 120px;
    height: 120px;
  }

  .second {
    display: block;
    padding: 0 !important;
  }
}

@media screen and (max-width: 1280px) {
  
  .poa__why-container-first {
    text-align: center;
    padding: 0;
    width: fit-content;
    margin-top: 3em;
  }

  .poa__why-container-head h1 {
    font-size: 64px;
  }

  .line-set {
    padding-left: 5%;
    padding-right: 5%;
    padding: 1rem 1rem;
  }

  .vertical {
    padding-right: 0px;
  }

  .second {
    padding-left: 3em;
  }

  .poa__first img {
    width: 100px;
    height: 100px;
  }

  .poa__why-container-second {
    padding: 0;
  }
}