.info {
  padding: 1rem;
}

.form__header_button {
  cursor: pointer;
}

.zoe {
  text-decoration: underline;
}

.btn-sm {
  margin: 0;
  display: flex;
  text-decoration: none;
  padding: .5rem;
}
@media (min-width: 1280px) {
  .info__two-main button {
    margin-top: 1.2rem !important;
  }
}
@media (max-width: 768px) {
  .info__two-main button {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important;
  }
}
@media (min-width: 768px) {
  .info {
    margin-bottom: 2rem;
    margin-top: 8rem;
  }
}
@media (min-width: 768px) {
  .info__one {
    padding: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.info__one-msg button {
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .info__one {
    flex-direction: column;
  }
}
.info__one-msg {
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .info__one-msg {
    width: 50%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .info__one-msg {
    width: 100%;
  }
}
.info__one-msg h4 {
  font-family: "Computer says no";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  color: #000000;
}
@media (min-width: 768px) {
  .info__one-msg h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 104px;
    line-height: 68px;
    width: 140%;
  }
}
.info__one-msg p {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: -0.04em;
  color: #000000;
  margin-top: 1.5rem;
}
@media (min-width: 768px) {
  .info__one-msg p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */
    letter-spacing: -0.04em;
    line-height: 30px;
  }
}
.info__one-msg button {
  margin-top: 1rem;
  background: #FFDE59;
  border-radius: 64px;
  border: 0;
  padding: 1rem;
}
@media (min-width: 768px) {
  .info__one-msg button {
    padding: 1.5rem;
  }
}
.info__one-hosts {
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .info__one-hosts {
    width: 70%;
    text-align: right;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .info__one-hosts {
    width: 100%;
    text-align: center;
  }
}
.info__one-hosts img {
  width: 100%;
}
@media (min-width: 768px) {
  .info__one-hosts img {
    width: 55%;
    margin: 0 auto;
  }
}
.info__two {
  margin-top: 2rem;
  background: #FFDE59;
  margin: -1rem;
  margin-top: 6rem;
  padding: 1rem;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .info__two {
    margin: -3rem;
    margin-top: 3rem;
  }
}
@media (min-width: 768px) {
  .info__two {
    text-align: center;
    height: 518px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .info__two {
    height: auto;
  }
}
.info__two h3 {
  margin-top: 1rem;
  font-family: "Computer says no";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  /* identical to box height, or 71% */
  /* Black */
  color: #000000;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .info__two h3 {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 104px;
    line-height: 68px;
    /* identical to box height, or 65% */
    text-align: center;
    text-transform: capitalize;
  }
}
.info__two-main {
  background: #FFFFFF;
  /* Black */
  border: 1px solid #000000;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}
.info__two-main button {
  text-align: left;
  padding: 0.7rem;
  background-color: #FFDE59;
  border: 0;
  border-radius: 99px;
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .info__two-main button {
    padding: 1rem;
  }
}
.info__two-main button img {
  margin-left: 0.5rem;
}
@media (min-width: 768px) {
  .info__two-main {
    width: 45%;
    height: 376px;
    margin-top: 6rem;
    padding-bottom: 0.1rem;
    height: 360px;
    padding: 2rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .info__two-main {
    height: auto;
    width: 80%;
    margin: 1rem auto;
  }
}
.info__two-main .check {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.info__two-main .check img {
  margin-right: 0.5rem;
  width: 15%;
}
@media (min-width: 768px) {
  .info__two-main .check img {
    width: 10%;
  }
}
.info__two-main .check p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #000000;
}
@media (min-width: 768px) {
  .info__two-main .check p {
    font-weight: 700;
    font-size: 2rem;
    line-height: 120%;
  }
}
.info__two-main p {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: -0.04em;
  color: #000000;
}
@media (min-width: 768px) {
  .info__two-main p {
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    letter-spacing: -0.04em;
  }
}

@media (min-width: 768px) {
  .ok {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ok {
    display: block;
    width: 100%;
  }
}

.pp {
  text-align: left;
}/*# sourceMappingURL=Info.css.map */