.poa__header {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4em;
}

.poa__header-container {
  padding-top: 120px;
  display: flex !important;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.poa__header-container_text {
  justify-content: center;
  align-self: center;
}

.poa__header-container_text h1 {
  font-size: 104px;
  font-weight: 300;
}

.poa__header-container_text h3 {
  font-size: 20px;
  font-weight: 400;
  margin-top: 16px;
  font-family: var(--font-family);
}

.poa__header-btn {
  margin-top: 24px;
  border: 0;
  background-color: var(--color-accent);
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 28px;
  padding-right: 28px;
  font-size: 20px;
  border-radius: 36px;
  cursor: pointer;
}

.poa__header_hero-img img {
  width: 600px;
}

.slick-list {
  height: 80vh;
}

.sliding {
  position: relative;
  padding-left: 0 !important;
}

.slider-image {
  position: relative;
  height: 0;
  padding-bottom: 55%;
  max-width: 100%;
}

.slider-image img {
  position: absolute;
  width: 100%;
  height: 80%;
  object-fit: cover;
}

.slider-content {
  position: absolute;
  top: 40%;
  left: 30%;
  transform: translate(-50%, -50%);
  text-align: left;
  z-index: 1;
  color: #fff;
  max-width: 47%;
}

.slider-content h1 {
  font-size: 112px;
  font-weight: 100;
  margin-bottom: 1rem;
}

.slider-content p {
  font-size: 20px;
  margin: 0;
  font-family: var(--font-family);
}

.coin {
  text-align: center;
  padding: 4rem;
}

.coin-img {
  display: flex;
  justify-content: center;
  padding-top: 9rem;
  text-align: center;
  padding: 4rem;
}

.coin-img img {
  width: 60%;
  height: 100%;
}

.coin-content {
  text-align: center;
}

.coin-content h1 {
  font-size: 112px;
  font-weight: 100;
  line-height: 72px;
}

.coin-content p {
  font-size: 20px;
  font-family: var(--font-family);
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
}



/* Media Queries */

@media screen and (max-width: 350px) {
  
  .poa__header {
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
  }

  .poa__header-container {
    display: block !important;
    max-width: 100% !important;
  }

  .poa__header-container_text h1 {
    font-size: 60px;
  }

  .poa__header-container h3 {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 20px;
  }

  .poa__header_hero-img img {
    width: 80%;
    height: auto;
    padding-top: 50px;
    margin-right: auto;
    margin-left: auto;
  }

  .poa__header-btn {
    margin-top: 0;
    padding: 1rem 24px;
    font-size: 16px;
  }

}

@media screen and (max-width: 650px) {
  poa__header {
    padding: 0;
  }

  .poa__header_hero-img img {
    width: 80% !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-top: 4em !important;
  }

  .poa__header-container {
    display: block !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .poa__header-container_text h1 {
    font-size: 52px !important;
  }

  .slider-content {
    position: relative;
    top: 0 !important;
    left: 45% !important;
    max-width: 80% !important;
  }

  .slider-image img {
    height: 400% !important;
    width: 100% !important;
  }

  .slider-content h1 {
    font-size: 64px;
    width: 100%
  }

  .slider-content p {
    font-size: 16px;
    width: 100%;
  }

  .coin {
    padding: 1rem;
    text-align: left;
  }

  .coin-content h1 {
    font-size: 64px;
    line-height: 40px;
    text-align: left;
  }

  .coin-content p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.04em;
    font-family: Work Sans;
    text-align: left;
    margin: 0;
    width: 100%;
  }

  .coin-img {
    padding: 1rem !important;
  }

  .coin-img img {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 5em;
    width: 120% !important;
    height: auto;
    text-align: center;
  }
}

@media screen and (max-width: 850px) {

  .poa__header-container {
    padding-top: 2rem;
  }

  .poa__header-container_text h1 {
    font-size: 60px;
  }

  .poa__header_hero-img img {
    width: 100%;
  }

  .poa__header-btn {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 18px;
  }

  .slider-image img {
    height: 160%;
  }

  .slider-content {
    top: 60%;
    left: 35%;
    max-width: 70%;
  }

  .slider-content h1 {
    font-size: 64px;
  }
}

@media screen and (max-width: 1280px) {
  .poa__second {
    display: block;
  }

  .coin-img img {
    height: auto;
  }
  
  .poa__header-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .poa__header_hero-img img {
    width: 100%;
  }

  .poa__header-container_text h1 {
    font-size: 85px;
  }

  .poa__header-btn {
    padding: 1rem 1.5rem 1rem 1.5rem;
    font-size: 18px;
  }

}