.poa__team-container {
  background: #FFF8DE;
  padding-bottom: 4em;
}

.poa__team--header {
  text-align: center;
}

.poa__team--header h1 {
  padding-top: 1em;
  padding-bottom: 0.5em;
  font-size: 104px;
  font-weight: 100;
  word-spacing: -16px;
}

.poa__team--sub-container {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  font-family: var(--font-family);
}

.poa__team--sub-container img {
  width: 300px;
  height: auto;
}

.poa__team--sub-container a {
  display: flex-end;
 
  justify-content: center;
  padding-right: 2px;
}

.poa__team--sub-container a img {
  width: 25px;
  padding-top: 12px;
  padding-left: 5px;
}

.poa__team-members {
  text-align: center;
}

.poa__team-members h1 {
  font-size: 20px;
}

.poa__team-members p {
  padding-top: 4px;
  width: 258px;
  font-weight: 300;
  margin: auto;
}

@media (min-width: 359px) and (max-width: 640px) {

  .poa__team-container {
    width: 100%;
    padding-bottom: 2em;
  }

  .poa__team--sub-container {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: -1em;
  }

  .poa__team--sub-container img {
    width: 280px;
    height: auto;
  }

  .poa__team--header h1 {
    font-size: 48px;
  }

  .poa__team-members {
    margin-bottom: 2em;
  }

 
}


@media screen and (max-width: 850px) {
  .poa__team--header h1 {
    padding-top: 1em;
    padding-bottom: 0.4em;
  }

  .poa__team--sub-container {
    width: 100%;
    display: block;
  }

  .poa__team-members {
    padding-bottom: 2em;
  }
}


@media screen and (max-width: 1280px) {
  .poa__team--header h1 {
    font-size: 80px;
  }

  .poa__team--sub-container {
    max-width: 100%;
  }

  .poa__team--sub-container img {
    width: 60%;
  }
}