.poa__main-footer {
  background: black;
  color: white;
  padding-top: 50em;
  margin-top: -37.5em;
  position: relative;
  z-index: -3;
  font-family: var(--font-family);
}

.mini-footer {
  z-index: 1;
}

.poa__main-footer > div {
  position: absolute;
  z-index: 6;
  background: black;
  width: 100%;
  padding: 4rem;
}

.poa__mini-footer {
  display: flex;
  justify-content: space-between;
}

.poa__footer-section form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid white;
  width: 100;
}

.poa__footer-section input {
  background: none;
  border: none;
  width: 380px;
  padding: 12px;
  font-size: 32px;
  color: rgb(133, 133, 133);
} 

.poa__footer-section button {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 8px;
  border-radius: 52px;
  background-color: var(--color-accent);
  border: 0;
  cursor: pointer;
  width: 160px;
  height: 48px;
  font-size: 16px;
}

.poa__footer h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.04em;
  padding-top: 24px;
  padding-bottom: 24px;
}

.poa--section-links {
  display: flex;
}

.links {
  margin-right: 2em;
}

.links p {
  font-size: 16px;
  line-height: 21px;
  padding-bottom: 8px;
}

.links h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  padding-bottom: 16px;
}

.links__social li {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}

.links__social h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  padding-bottom: 16px;
}

.links__social img {
  padding-right: 4px;
}

.p {
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.footer__three {
  display: flex;
  padding-top: 12px;
  justify-content: space-between;
}

.footer__three-one {
  display: flex;
  width: 30%;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .poa__main-footer > div {
    padding: 16px;
    width: 100%;
  }

  .poa__mini-footer {
    display: block;
  }

  .poa__footer-section {
    width: 100% !important;
  }

  .some-hr {
    display: block;
    margin-top: 2rem;
  }

  .poa__footer-section img {
    width: 50%;
  }

  .poa__footer-section input {
    width: 100%;
    font-size: 20px;
  }

  .poa__footer-section button {
    height: 30px;
    padding: 10px 12px;
    gap: 4px;
    font-size: 14px;
  }

  .poa__footer-section button img {
    width: 16px;
    height: 16px;
  }

  .poa--section-links {
    justify-content: space-between;
    padding-top: 2em;
    flex-direction: column;
  }

  .links {
    margin: 0;
  }

  .links__social {
    padding-top: 2rem;
  }

  .footer__three-one {
    
  }

  .footer__three-one p {
    padding: 10px;
  }
}

@media screen and (max-width: 850px) {
  .poa__mini-footer {
    display: block;
  }

  .poa--section-links {
    justify-content: space-between;
  }

  .footer__three {
    flex-direction: column;
  }

  .footer__three-one {
    width: 100%;
    padding-top: 12px;
  }
}

@media screen and (max-width: 1280px) {

  .poa__mini-footer {
    flex-direction: column;
  }

  .poa__footer-section {
    width: 565px;
  }

  .footer__three-one {
    width: 50%;
  }

}