.map {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5em;
  width: 100%;
  height: auto;
}

.map-mobile {
  display: none;
  width: 100%;
  height: 100%;
  max-width: 375px;
  max-height: 523px;
  object-fit: cover;
  object-position: center;
}

.poa__calculator {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 4rem;
  margin-top: -30em;
  justify-content: space-between;
}

.poa__calculator-one h1 {
  font-weight: 100;
  font-size: 80px;
}

.poa__calculator-one p {
  font-size: 20px;
  font-weight: 400;
  font-family: var(--font-family);
  margin-top: 1em;
}

.poa__calculator-one {
  width: 580px;
}

input#billAmount {
  width: 25%;
}

.poa__calculator-form {
  padding: 1rem;
  background-color: white;
  box-shadow: 113px 129px 69px rgba(112, 109, 97, 0.01), 28px 32px 43px rgba(112, 109, 97, 0.09);
  border-radius: 16px;
  font-family: var(--font-family);
  width: 400px;
}

.poa__form-head {
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 32px;
}

.poa__calculator-form div {
  display: flex;
}

.poa_form-one {
  justify-content: space-between;
}

.poa_form-one h1 {
  margin-right: 6em;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 16px;
}

.poa_form-two h1 {
  font-size: 26px;
  font-weight: 400;
  margin-right: 6.5em;
}

.poa_form-three h1 {
  font-size: 26px;
  font-weight: 400;
  margin-right: 3em;
}

.poa_form-two {
  padding-top: 1em;
  flex-direction: column;
}

.poa_form-two div {
  justify-content: space-between;
}

.poa_form-three {
  padding-top: 1em;
}


@media (min-width: 359px) and (max-width: 640px) {

  .poa__calculator-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4em;
  }

  .poa__calculator {
    display: block;
    padding: 0;
    padding-top: 1rem !important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .poa__calculator-one {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 60px;
  }

  .poa__calculator-one h1 {
    font-size: 48px;
  }

  .poa__calculator-one p {
    font-size: 16px;
    width: 340px;
    margin: auto;
    padding-top: 1rem;
  }

  .map {
    display: none;
  }

  .map-mobile {
    display: block;
    padding: 0;
    margin: 0;
    max-width: 100% !important;
    height: auto;
    object-fit: cover;
    object-position: center;
  }

  .poa__calculator-form {
    padding: 1rem;
    width: 340px;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-color: white;
    box-shadow: 113px 129px 69px rgba(112, 109, 97, 0.01), 28px 32px 43px rgba(112, 109, 97, 0.09);
    border-radius: 16px;
    font-family: var(--font-family);
  }

  .poa__form-head {
    font-size: 22px;
  }

  .poa_form-one h1 {
    font-size: 16px;
  }

  .poa_form-two h1 {
    font-size: 16px;
  }

  .poa_form-three h1 {
    font-size: 16px;
  }

  .poa__calculator-form hr {
    display: block;
    width: 300px;
  }
}

@media (min-width: 767px) and (max-width: 920px) {

  .poa__calculator {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -55em;
    margin-bottom: 20em;
    padding-top: 1em;
  }

  .poa__calculator-one {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 1em;
  }

  .map {
    display: none;
  }

  .map-mobile {
    display: block;
    max-width: none;
    max-height: none;
  }

  .poa__calculator {
    width: auto;
    display: block;
  }

  .poa__calculator-form {
    position: relative;
    width: 460px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;
  }
}

@media screen and  (max-width: 1280px) {
  .poa__calculator {
    padding-top: 10em;
  }  
}