.header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  max-width: 1440px;
  margin: 0 auto;
}
.header .list {
  display: flex;
  align-items: center;
  display: none;
}

.accordion-content a {
  text-decoration: none;
}

@media (min-width: 768px) {
  .header .list {
    display: flex;
    align-items: center;
  }
  .header ul {
    display: flex;
    align-items: center;
  }

  .header ul a {
    text-decoration: none;
  }

  .header ul .ul {
    display: block;
    margin-top: 1rem;
    position: absolute;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0.8rem;
  }
  .header ul li {
    list-style: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    /* Black */
    color: #000000;
    margin-right: 1rem;
    text-align: left;
  }
  .header button {
    border: 0;
    background: #FFDE59;
    border-radius: 64px;
    padding: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-left: 3rem;
  }
}
@media (min-width: 768px) {
  .header__menu {
    display: none;
  }
}

.notactive {
  position: absolute;
  height: 0px;
  visibility: hidden;
  background-color: white;
}
@media (min-width: 768px) {
  .notactive {
    display: none;
  }
}

.active {
  position: absolute;
  background-color: white;
  height: auto;
  width: 100%;
  padding: 1rem;
  text-align: center;
}
@media (min-width: 768px) {
  .active {
    display: none;
  }
}
.active li {
  text-align: left;
  list-style: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */
  /* Black */
  color: #000000;
}
.active button {
  margin: 0 auto;
  border: 0;
  background: #FFDE59;
  border-radius: 64px;
  padding: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-top: 2rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */
  text-align: center;
  width: 100%;
  /* Black */
  color: #000000;
}

a {
  text-decoration: none;
  text-align: left;
}/*# sourceMappingURL=header.css.map */