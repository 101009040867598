.poa__steps-container {
  margin-top: 13em;
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
}

.poa__steps-header {
  margin-left: auto;
  margin-right: auto;
}

.poa__steps-header h1 {
  text-align: center;
  font-size: 104px;
  font-weight: 100;
  margin-bottom: 1.25em;
}

.poa-steps__container {
  display: flex;
  width: 1300px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: space-evenly;
}

.poa-steps__sub {
  width: 380px;
  text-align: center;
  font-family: var(--font-family);
}

.poa-steps__sub img {
  margin-bottom: 20px;
  width: 80px;
  height: 80px;
}

.poa-steps__sub h1 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 12px;
}

.poa-steps__sub p {
  font-size: 14px;
  font-weight: 400;
  width: 328px;
  margin-left: auto;
  margin-right: auto;
}

.cross {
  margin-top: -16em;
  margin-left: -9em;
}

.sda {
  margin-left: -9em;
}

.criss {
  margin-top: -5em;
  margin-left: -9em;
}

.reward {
  margin-left: -9em;
}


@media screen and (max-width: 650px) {
  .poa__steps-container {
    width: 100%;
    margin-top: 8em;
  }

  .poa-steps__sub {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 40px;
  }

  .poa-steps__sub p {
    font-size: 16px;
  }

  .poa-steps__container {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .criss {
    display: none;
  }

  .cross {
    display: none;
  }

  .poa__steps-header h1 {
    font-size: 64px !important;
    padding-top: 1em;
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 850px) { 
  .poa__steps-header h1 {
    font-size: 80px;
    padding-top: 2em;
  }

  .poa-steps__container {
    display: block;
    width: 100% !important;
  }

  .criss {
    display: none;
  }

  .cross {
    display: none;
  }

  .poa-steps__sub {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4em;
  }
}

@media screen and (max-width: 1280px) {

  .poa__steps-container {
    margin-top: 4em;
  }

  .poa-steps__container {
    width: 100%;
  }

  .poa__steps-header h1 {
    font-size: 80px;
    padding-top: .5em;
  }

  .poa-steps__sub p {
    width: 60%;
  }

  .cross {
    width: 200px;
  }

  .criss {
    width: 200px;
    margin-top: -8em;
  }
}