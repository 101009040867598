.info{
    padding: 1rem;
    

    @media (min-width: 768px){
       padding: 3rem;
       margin-bottom: 2rem;
       margin-top: 8rem;
    }
    &__one{
        @media (min-width: 768px){
            display: flex;
            align-items: center;
            justify-content: space-between;
         }
         @media only screen and (min-width: 768px) and (max-width: 1023px) {
              flex-direction: column;
        }
        &-msg{
            margin-top: 2rem;
            @media (min-width: 768px){
                width: 50%;
             }
             @media only screen and (min-width: 768px) and (max-width: 1023px) {
                width: 100%;
            }
            h4{
                font-family: 'Computer says no';
                font-style: normal;
                font-weight: 400;
                font-size: 40px;
                line-height: 40px;
                color: #000000;

                @media (min-width: 768px){
                    font-style: normal;
                    font-weight: 400;
                    font-size: 104px;
                    line-height: 68px;
                    
                    width: 140%;
                    
                 }
            }
    
            p{
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                /* or 150% */
                letter-spacing: -0.04em;
                color: #000000;
                margin-top: 1.5rem;

                @media (min-width: 768px){
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 30px;
                    /* or 150% */

                    letter-spacing: -0.04em;
                    line-height: 30px;
                 }
            }
    
            button{
                margin-top: 1rem;
                background: #FFDE59;
                border-radius: 64px;
                border: 0;
                padding: 1rem;
                

                @media (min-width: 768px){
                    padding: 1.5rem;
                 }


            }
        }

        &-hosts{
            margin-top: 2rem;
            @media (min-width: 768px){
                
                width: 70%;
                text-align: right;
               
             }
             @media only screen and (min-width: 768px) and (max-width: 1023px) {
                width: 100%;
                text-align: center;
          }
            
            img{
                width: 100%;
                @media (min-width: 768px){
                   width: 55%;
                   margin: 0 auto;
                 }
            }
        }
    }

    &__two{
        margin-top: 2rem;
        background: #FFDE59;
        margin: -1rem;
        margin-top: 6rem;
        padding: 1rem;
        @media only screen and (min-width: 768px) and (max-width: 1023px) {
            margin: -3rem;
            margin-top: 3rem;
        }

        @media (min-width: 768px){
            text-align: center;
            height: 518px;
          }
          @media only screen and (min-width: 768px) and (max-width: 1023px) {
            height: auto;
      }
        
        h3{
            @media (min-width: 768px){
               text-align: center;
               margin-top: 4rem;
               margin-bottom: 1rem;
               font-style: normal;
                font-weight: 400;
                font-size: 104px;
                line-height: 68px;
                /* identical to box height, or 65% */

                text-align: center;
                text-transform: capitalize;
             }
            margin-top: 1rem;
            font-family: 'Computer says no';
            font-style: normal;
            font-weight: 400;
            font-size: 40px;
            line-height: 40px;
            /* identical to box height, or 71% */


            /* Black */

            color: #000000;
            margin-bottom: 2rem;
            
        }

        &-main{
        background: #FFFFFF;
        /* Black */

        border: 1px solid #000000;
        border-radius: 12px;
        padding: 1rem;
        
        margin-bottom: 1rem;
        text-align: left;
       

        button{
            text-align: left;
            padding: .7rem;
            background-color: #FFDE59;
            border: 0;
            border-radius: 25px;
            margin-top: .3rem;
            display: flex;
            align-items: center;

            @media (min-width: 768px){
               padding: 1rem;
              }

            img{
                margin-left: .5rem;
            }
            
        }

        @media (min-width: 768px){
            width: 45%;
            height: 376px;
            margin-top: 6rem;
            padding-bottom: .1rem;
            height: 360px;
            padding: 2rem;
          }

          @media only screen and (min-width: 768px) and (max-width: 1023px) {
            height: auto;
            width: 80%;
            margin: 1rem auto;
        }
            .check{
                display: flex;
                align-items: center;
                margin-bottom: 1rem;

                img{
                    margin-right: .5rem;
                    width: 15%;
                    @media (min-width: 768px){
                        width: 10%;
                      }
                }

                p{
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 120%;

                    color: #000000;
                    @media (min-width: 768px){
                        font-weight: 700;
                        font-size: 2rem;
                        line-height: 120%;
                      }
                }
            }

            p{
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                /* or 150% */
                letter-spacing: -0.04em;
                color: #000000;
                @media (min-width: 768px){
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 40px;
                    letter-spacing: -0.04em;
                  }
            }
        }
    }
}

.ok{
    @media (min-width: 768px){
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        display: block;
        width: 100%;
    }
}

.pp{
    text-align: left;
}